<template>
  <div class="flex-row align-items-center">
    <CRow class="justify-content-center headimg mb-5">
      <CCol md="12" class="p-0">
        <div
          style="
            background-image: url('img/Dashboard.png');
            background-position: 50% 50%;
            background-size: cover;
            height: 200px;
          "
        >
          <h2 class="text-center text-white middle-center">
            <b
              >Detail Permohonan <br />
              <span
                v-if="detail_data && detail_data.modules"
                class="text-success"
                >{{ detail_data.modules.m_nama }}</span
              ></b
            >
          </h2>
        </div>
      </CCol>
    </CRow>
    <div class="container">
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard>
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h3 class="mb-0 text-center">Detail Permohonan</h3>
            </CCardHeader>
            <CCardBody>
              <CTabs>
                <CTab title="Detail Permohonan" active>
                  <CRow>
                    <div class="col-12 my-3">
                      <h3 class="mb-0">
                        Detail Permohonan {{ detail_data.kode_permohonan }}
                      </h3>
                    </div>
                    <div class="col">
                      <table class="w-100" border="0">
                        <tr>
                          <th style="width: 250px">No. Surat Permohonan</th>
                          <td style="width: 15px">:</td>
                          <td v-if="detail_data && detail_data.modules">
                            {{ detail_data.permohonan.pr_nomor_surat }}
                          </td>
                        </tr>
                        <tr>
                          <th style="width: 250px">Tanggal Surat</th>
                          <td style="width: 15px">:</td>
                          <td v-if="detail_data && detail_data.permohonan">
                            {{ detail_data.permohonan.pr_tanggal }}
                          </td>
                        </tr>
                        <tr>
                          <th style="width: 250px">Perihal</th>
                          <td style="width: 15px">:</td>
                          <td v-if="detail_data && detail_data.permohonan">
                            {{ detail_data.permohonan.pr_perihal }}
                          </td>
                        </tr>
                        <tr>
                          <th style="width: 250px">
                            Penanggung Jawab Surat Keputusan
                          </th>
                          <td style="width: 15px">:</td>
                          <td v-if="detail_data && detail_data.permohonan">
                            {{
                              JSON.parse(
                                detail_data.permohonan.json_penanggung_jawab
                              ).nama_penanggung_jwb
                            }}
                            ({{
                              JSON.parse(
                                detail_data.permohonan.json_penanggung_jawab
                              ).jabatan_penanggung_jwb
                            }})
                          </td>
                        </tr>
                        <tr v-if="detail_data && detail_data.permohonan">
                          <th style="width: 250px">Perlakuan Fisik/Kimia</th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.permohonan.pr_perlakuan }}</td>
                        </tr>
                        <tr v-if="detail_data && detail_data.negara_asal">
                          <th style="width: 250px">Negara Asal Benih</th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.negara_asal.n_nama }}</td>
                        </tr>
                        <tr v-if="detail_data && detail_data.permohonan">
                          <th style="width: 250px">Nama Produsen</th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.permohonan.pr_nama_produsen }}</td>
                        </tr>
                        <tr v-if="detail_data && detail_data.negara_pengirim">
                          <th style="width: 250px">Negara Pengirim</th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.negara_pengirim.n_nama }}</td>
                        </tr>
                        <tr v-if="detail_data && detail_data.permohonan">
                          <th style="width: 250px">Nama Pengirim</th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.permohonan.pr_nama_pengirim }}</td>
                        </tr>
                        <tr v-if="detail_data && detail_data.permohonan">
                          <th style="width: 250px">Alamat Pengirim</th>
                          <td style="width: 15px">:</td>
                          <td>
                            {{ detail_data.permohonan.pr_alamat_pengirim }}
                          </td>
                        </tr>
                        <tr v-if="detail_data && detail_data.permohonan">
                          <th style="width: 250px">Pekerjaan Pengirim</th>
                          <td style="width: 15px">:</td>
                          <td>
                            {{ detail_data.permohonan.pr_pekerjaan_pengirim }}
                          </td>
                        </tr>
                        <tr v-if="detail_data && detail_data.pelabuhan">
                          <th style="width: 250px">Tempat Pengeluaran</th>
                          <td style="width: 15px">:</td>
                          <td>
                            {{ detail_data.pelabuhan.pl_code }} -
                            {{ detail_data.pelabuhan.pl_nama }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            detail_data && detail_data.tujuan_penggunaan_benih
                          "
                        >
                          <th style="width: 250px">Tujuan Pengeluaran Benih</th>
                          <td style="width: 15px">:</td>
                          <td>
                            {{ detail_data.tujuan_penggunaan_benih.tpb_nama }}
                          </td>
                        </tr>
                        <tr
                          v-if="
                            detail_data &&
                            detail_data.permohonan &&
                            detail_data.permohonan.pr_kategori ==
                              'Sumber Daya Genetik'
                          "
                        >
                          <th style="width: 250px">
                            Keterangan Tujuan Pengeluaran Benih
                          </th>
                          <td style="width: 15px">:</td>
                          <td>{{ detail_data.permohonan.pr_keterangan }}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td style="width: 15px">:</td>
                          <td>
                            <template>
                              <div
                                class="badge badge-success"
                                v-if="
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama == 'Selesai'
                                "
                              >
                                Selesai
                              </div>
                              <div
                                class="badge badge-danger"
                                v-else-if="
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama == 'Dibatalkan'
                                "
                              >
                                Permohonan Dibatalkan
                              </div>
                              <div
                                class="badge badge-warning"
                                v-else-if="
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama ==
                                  'Menunggu Perbaikan'
                                "
                              >
                                {{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama
                                }}
                                oleh Pemohon
                              </div>
                              <div
                                class="badge badge-primary"
                                v-else-if="
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama ==
                                  'Evaluasi Dokumen Teknis'
                                "
                              >
                                Menunggu
                                {{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.st_nama
                                }}
                                oleh
                                <span class="text-uppercase">{{
                                  permohonan_status.status_permohonan_terbaru
                                    .status_permohonan.roles.ditjen
                                }}</span>
                              </div>
                              <div v-else>
                                <div class="badge badge-info">
                                  Menunggu
                                  {{
                                    permohonan_status.status_permohonan_terbaru
                                      .status_permohonan.st_nama
                                  }}
                                  oleh
                                  <span class="text-uppercase">{{
                                    permohonan_status.status_permohonan_terbaru
                                      .status_permohonan.roles.ditjen
                                  }}</span>
                                </div>
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <th style="width: 250px">Data benih</th>
                          <td style="width: 15px">:</td>
                          <td></td>
                        </tr>
                      </table>
                    </div>
                    <div
                      class="col-4 text-center"
                      v-if="
                        permohonan_status &&
                        permohonan_status.status_permohonan_terbaru &&
                        permohonan_status.status_permohonan_terbaru
                          .status_permohonan &&
                        permohonan_status.status_permohonan_terbaru
                          .status_permohonan.st_nama == 'Selesai'
                      "
                    >
                      <img src="img/approved.jpg" style="width: 150px" alt="" />
                      <br /><br />
                      <div class="btn-group">
                        <div
                          class="btn btn-success"
                          v-on:click="
                            getFileWithLink(detail_data.dokumen_hasil[0].link)
                          "
                        >
                          <i class="fa fa-download"></i> Unduh Surat Keputusan
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <table
                        class="table table-bordered table-sm w-100"
                        id="table-benih"
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th style="width: 15px">No</th>
                            <th>Jenis Tanaman</th>
                            <th>Nama Latin</th>
                            <th>Nama Varietas</th>
                            <th>Bentuk Benih</th>
                            <th>Jumlah</th>
                            <th>Jumlah Disetujui</th>
                            <th>Satuan</th>
                            <!-- <th>Nilai <span>(Total Harga dalam USD)</span></th> -->
                            <th>Kode HS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template
                            v-if="
                              detail_data &&
                              detail_data.permohonan &&
                              detail_data.permohonan.permohonan_benih
                            "
                          >
                            <tr
                              v-for="(value_benih, index_benih) in detail_data
                                .permohonan.permohonan_benih"
                              :key="index_benih"
                            >
                              <td>{{ index_benih + 1 }}</td>
                              <td>{{ value_benih.tanaman.t_nama }}</td>
                              <td>{{ value_benih.pb_nama_latin }}</td>
                              <td>{{ value_benih.pb_varietas }}</td>
                              <td>{{ value_benih.bentuk_benih.bb_nama }}</td>
                              <td>{{ value_benih.pb_jumlah }}</td>
                              <td>{{ value_benih.pb_jumlah_disetujui }}</td>
                              <td>{{ value_benih.satuan.s_nama }}</td>
                              <!-- <td>{{ value_benih.pb_nilai_total }}</td> -->
                              <td>{{ value_benih.pb_kode_hs }}</td>
                            </tr>
                            <template
                              v-if="
                                permohonan_status.status_permohonan_terbaru
                                  .status_permohonan.st_nama == 'Selesai'
                              "
                            >
                              <tr
                                style="height: 20px; background-color: #f5f5f5"
                              >
                                <td colspan="10"></td>
                              </tr>
                              <tr style="font-weight: bold">
                                <td colspan="2">
                                  Total Jumlah Benih diajukan :
                                </td>
                                <td colspan="8">
                                  {{ totalPbJumlah }}
                                  {{
                                    detail_data.permohonan.permohonan_benih[0]
                                      .satuan.s_nama
                                  }}
                                </td>
                              </tr>
                              <tr style="font-weight: bold">
                                <td colspan="2">
                                  Total Jumlah Benih disetujuai :
                                </td>
                                <td colspan="8">
                                  {{ totalPbJumlahDisetujui }}
                                  {{
                                    detail_data.permohonan.permohonan_benih[0]
                                      .satuan.s_nama
                                  }}
                                </td>
                              </tr>
                              <tr style="font-weight: bold">
                                <td colspan="2">
                                  Total Nilai (Total Harga dalam USD) :
                                </td>
                                <td colspan="8">{{ totalPbNilaiTotal }}</td>
                              </tr>
                            </template>
                          </template>
                        </tbody>
                      </table>
                      <small v-if="benih_aropt"
                        >*) Data benih yang terdeteksi AROPT tidak diproses pada
                        permohonan ini</small
                      >
                      <table
                        class="w-100"
                        border="0"
                        v-if="
                          detail_data.tujuan_penggunaan_benih &&
                          detail_data.tujuan_penggunaan_benih.id == 3
                        "
                      >
                        <tr>
                          <th style="width: 250px">Kelompok Tani</th>
                          <td style="width: 15px">:</td>
                          <td></td>
                        </tr>
                      </table>
                      <table
                        class="table table-bordered table-sm"
                        v-if="
                          detail_data.tujuan_penggunaan_benih &&
                          detail_data.tujuan_penggunaan_benih.id == 3
                        "
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th style="width: 15px">No</th>
                            <th>Nama Kelompok Tani</th>
                            <th>NIK</th>
                            <th>NO. HP</th>
                            <th>Titik Koordinat</th>
                            <th>Keb Benih</th>
                            <th>Luas Lahan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template>
                            <tr
                              v-if="
                                detail_data.permohonan
                                  .permohonan_kelompok_tani &&
                                detail_data.permohonan.permohonan_kelompok_tani
                                  .length == 0
                              "
                            >
                              <td colspan="6">
                                <i class="fa fa-times"></i> Data Tidak Tersedia
                              </td>
                            </tr>
                            <tr
                              v-for="(
                                value_row_kelompok_tani, key_row_kelompok_tani
                              ) in detail_data.permohonan
                                .permohonan_kelompok_tani"
                              :key="key_row_kelompok_tani"
                              v-else
                            >
                              <td>{{ key_row_kelompok_tani + 1 }}</td>
                              <td>
                                {{ value_row_kelompok_tani.nama_kelompok_tani }}
                              </td>
                              <td>
                                {{ value_row_kelompok_tani.nik }}
                              </td>
                              <td>
                                {{ value_row_kelompok_tani.no_hp }}
                              </td>
                              <td>
                                {{ value_row_kelompok_tani.titik_koordinat }}
                              </td>
                              <td>
                                {{ value_row_kelompok_tani.kebun_benih }}
                              </td>
                              <td>
                                {{ value_row_kelompok_tani.luas_lahan }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </CRow>
                </CTab>
                <CTab title="Dokumen Permohonan">
                  <CRow>
                    <CCol md="12" class="mt-3">
                      <CTabs variant="pills">
                        <CTab title="Dokumen Persyaratan" active>
                          <h3 class="mb-0 my-3">Dokumen Persyaratan</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 150px">File</th>
                                <th style="width: 250px">Keterangan</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-if="
                                  detail_data &&
                                  detail_data.permohonan &&
                                  detail_data.permohonan.permohonan_dokumen
                                "
                              >
                                <tr
                                  v-for="(
                                    value_doc_persyaratan, index_doc_persyaratan
                                  ) in detail_data.permohonan
                                    .permohonan_dokumen"
                                  :key="index_doc_persyaratan"
                                >
                                  <td>{{ index_doc_persyaratan + 1 }}</td>
                                  <td>
                                    {{
                                      value_doc_persyaratan.dokumen_permohonan
                                        .dokumen_persyaratan_permohonan.dpp_nama
                                    }}
                                  </td>
                                  <td>
                                    <!-- Logika untuk 'Laporan realisasi' dengan sip_reference -->
                                    <div
                                      v-if="
                                        value_doc_persyaratan.dokumen_permohonan
                                          .dokumen_persyaratan_permohonan
                                          .dpp_nama === 'Laporan realisasi'
                                      "
                                    >
                                      <div
                                        v-if="
                                          detail_data.permohonan.sip_reference
                                        "
                                      >
                                        <div
                                          v-if="
                                            detail_data.doc_realisasi_ref &&
                                            detail_data.doc_realisasi_ref.file
                                          "
                                          class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                          @click="
                                            getFile(
                                              detail_data.doc_realisasi_ref.file
                                            )
                                          "
                                        >
                                          <i class="fa fa-eye"></i> Lihat
                                        </div>
                                        <button
                                          v-else
                                          type="button"
                                          class="btn btn-sm btn-info rounded-0"
                                          @click="modalUploadRealisasi = true"
                                        >
                                          <i class="fa fa-upload"></i>
                                          Upload Dokumen
                                        </button>
                                      </div>
                                      <div
                                        v-else-if="value_doc_persyaratan.file"
                                        class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                        @click="
                                          getFile(value_doc_persyaratan.file)
                                        "
                                      >
                                        <i class="fa fa-eye"></i> Lihat
                                      </div>
                                      <div v-else>Data tidak tersedia</div>
                                    </div>

                                    <!-- Logika untuk dokumen selain 'Laporan realisasi' -->
                                    <template v-else>
                                      <div
                                        v-if="value_doc_persyaratan.file"
                                        class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                        @click="
                                          getFile(value_doc_persyaratan.file)
                                        "
                                      >
                                        <i class="fa fa-eye"></i> Lihat
                                      </div>
                                      <div v-else>Data tidak tersedia</div>
                                    </template>
                                    <!-- <template>
                                      <div
                                        v-if="value_doc_persyaratan.file"
                                        target="_blank"
                                        class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                        v-on:click="
                                          getFile(value_doc_persyaratan.file)
                                        "
                                      >
                                        <i class="fa fa-eye"></i> Lihat
                                      </div>
                                      <div v-else>Data tidak tersedia</div>
                                    </template> -->
                                  </td>
                                  <td></td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </CTab>
                        <CTab title="Dokumen Proses">
                          <h3 class="mb-0 my-3">Dokumen Proses</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 100px">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Excel Permohonan Benih</td>
                                <td>
                                  <div
                                    target="_blank"
                                    class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                    v-on:click="getExcelBenih()"
                                  >
                                    <i class="fa fa-download"></i> Unduh
                                  </div>
                                </td>
                              </tr>
                              <template>
                                <tr
                                  v-for="(
                                    value_doc_proses, index_doc_proses
                                  ) in detail_data.dokumen_proses"
                                  :key="index_doc_proses"
                                >
                                  <td>
                                    <template>
                                      <span v-if="index_doc_proses == 0">
                                        {{ index_doc_proses + 2 }}
                                      </span>
                                      <span v-else>
                                        {{ index_doc_proses + 1 }}
                                      </span>
                                    </template>
                                  </td>
                                  <td>{{ value_doc_proses.nama_dokumen }}</td>
                                  <td>
                                    <div
                                      target="_blank"
                                      class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                      v-on:click="
                                        getFileWithLink(value_doc_proses.link)
                                      "
                                    >
                                      <i class="fa fa-eye"></i> Lihat
                                    </div>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </CTab>
                        <CTab title="Dokumen Hasil">
                          <h3 class="mb-0 my-3">Dokumen Hasil</h3>
                          <table class="table table-sm table-bordered">
                            <thead class="bg-dark text-hite">
                              <tr class="text-center">
                                <th style="width: 15px">No</th>
                                <th>Dokumen Permohonan</th>
                                <th style="width: 100px">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                v-if="
                                  permohonan_status &&
                                  permohonan_status.status_permohonan_terbaru
                                "
                              >
                                <tr
                                  v-if="
                                    detail_data.dokumen_hasil == '' ||
                                    (permohonan_status.status_permohonan_terbaru
                                      .status_permohonan.st_nama != 'Selesai' &&
                                      permohonan_status
                                        .status_permohonan_terbaru
                                        .status_permohonan.st_nama !=
                                        'Dibatalkan')
                                  "
                                >
                                  <td colspan="3">Dokumen Belum Tersedia</td>
                                </tr>
                                <tr
                                  v-for="(
                                    value_doc_hasil, index_doc_hasil
                                  ) in detail_data.dokumen_hasil"
                                  :key="index_doc_hasil"
                                  v-else
                                >
                                  <td>{{ index_doc_hasil + 1 }}</td>
                                  <td>{{ value_doc_hasil.nama_dokumen }}</td>
                                  <td v-if="permohonan_status">
                                    <template>
                                      <div
                                        v-if="
                                          permohonan_status
                                            .status_permohonan_terbaru
                                            .status_permohonan.st_nama ==
                                            'Dibatalkan' &&
                                          value_doc_hasil.jenis_dokumen ==
                                            'Dokumen Generate'
                                        "
                                      >
                                        Dokumen Dibatalkan
                                      </div>
                                      <div v-else>
                                        <div
                                          target="_blank"
                                          class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                                          v-on:click="
                                            getFileWithLink(
                                              value_doc_hasil.link
                                            )
                                          "
                                        >
                                          <i class="fa fa-eye"></i> Lihat
                                        </div>
                                      </div>
                                    </template>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </CTab>
                      </CTabs>
                    </CCol>
                  </CRow>
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="12">
          <CCard class="top-radius-15">
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h3 class="mb-0 text-center">Timeline</h3>
            </CCardHeader>
            <CCardBody style="overflow: scroll" class="p-2">
              <ul class="timeline timeline-horizontal">
                <template
                  v-if="
                    detail_data &&
                    detail_data.permohonan &&
                    detail_data.permohonan.permohonan_timeline
                  "
                >
                  <li
                    class="timeline-item"
                    v-for="(value_timelime, index_timelime) in detail_data
                      .permohonan.permohonan_timeline"
                    :key="index_timelime"
                  >
                    <template>
                      <div
                        class="timeline-badge danger"
                        v-if="
                          value_timelime.status == 'ditolak' ||
                          value_timelime.status == 'dibatalkan'
                        "
                      >
                        <i class="fa fa-times" style="margin-top: 12px"></i>
                      </div>
                      <div class="timeline-badge success" v-else>
                        <i class="fa fa-check" style="margin-top: 12px"></i>
                      </div>
                    </template>
                    <div class="timeline-panel">
                      <div class="timeline-heading">
                        <b class="timeline-title">
                          {{ value_timelime.jabatan || value_timelime.users.roles }}</b
                        >
                        <p>
                          <small class="text-muted"
                            ><i class="fa fa-clock-o"></i>
                            {{ value_timelime.pt_tanggal }}</small
                          >
                        </p>
                      </div>
                      <div class="badge badge-info">
                        {{ value_timelime.pt_status_keterangan }}
                      </div>
                      <br />
                      <div
                        class="timeline-body"
                        v-if="value_timelime.pt_keterangan"
                      >
                        <b>Catatan : </b><br />
                        <p>{{ value_timelime.pt_keterangan }}</p>
                      </div>
                    </div>
                  </li>
                </template>
              </ul>

              <!-- form upload dokumen realisasi -->
              <template>
                <div>
                  <CModal
                    title="Upload Dokumen Realisasi"
                    color="btn-primary"
                    :show.sync="modalUploadRealisasi"
                    size="lg"
                  >
                    <CRow>
                      <CCol md="6">
                        <div class="form-group">
                          <label>File laporan realisasi</label>
                          <input
                            type="file"
                            class="form-control mb-0"
                            :id="'files-doc-realisasi'"
                            v-on:change="inputFileDocument"
                          />
                          <div
                            class="respond-input-file float-left"
                            :id="'docloading-realisasi'"
                          ></div>
                        </div>
                      </CCol>
                    </CRow>
                    <template #footer>
                      <CButton
                        @click="modalUploadRealisasi = false"
                        color="secondary"
                        >Tutup</CButton
                      >
                      <CButton
                        v-on:click="submitDokumenRealisasi()"
                        color="primary"
                        >Simpan</CButton
                      >
                    </template>
                  </CModal>
                </div>
              </template>

              <!-- form upload dokumen realisasi selesai -->
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
const null_array = [];
export default {
  name: "DetailPermohonanPemasukanPemohon",
  data() {
    return {
      idpermohonan: this.$route.params.idpermohonan,
      detail_data: null_array,
      data_benih: null_array,
      permohonan_status: null_array,
      benih_aropt: false,
      modalUploadRealisasi: false,
      file_dokumen_realisasi: {
        dokumen_permohonan_id: "",
        nama_dpp: "Laporan Realisasi",
        file: "",
      },
    };
  },
  methods: {
    normalizeUrl(url) {
      // Mencari semua instans dua slash atau lebih dan menggantinya dengan satu slash,
      // kecuali dua slash setelah protokol (http:, https:, file:, dan lain-lain).
      return url.replace(/([^:])\/{2,}/g, "$1/");
    },

    inputFileDocument(event) {
      const file = event.target.files[0];
      console.log(file);
      if (file) {
        if (file.size < 2242880) {
          $("#docloading-realisasi").html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var permohonan_dokumen_in = this.file_dokumen_realisasi;
          var urlres = this.apiLink;
          var cleanUrl = this.normalizeUrl;
          var data = new FormData();
          data.append("file", file);
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            permohonan_dokumen_in.file = response.data.data.file;
            // urlres += response.data.data.url;
            const newUrl = cleanUrl(urlres + response.data.data.url);
            $("#docloading-realisasi").html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                newUrl +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
            $("#doc-url").html(
              '<span class="badge badge-success p-1 m-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                newUrl +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat</span></a>'
            );
          });
        } else {
          alert("Max File 2 MB");
        }
      }
    },

    submitDokumenRealisasi() {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var data = new FormData();
      data.append("permohonan_id", this.$route.params.idpermohonan);
      data.append(
        "realisasi_dokumen",
        JSON.stringify(self.file_dokumen_realisasi)
      );
      var config = {
        method: "post",
        url: self.apiLink + "api/permohonan_realisasi/import-file",
        headers: {
          Authorization: "Bearer " + self.access_token,
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          self.modalUploadRealisasi = false;
          var res_data = response.data;
          if (res_data.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_data.data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Dokumen realisasi berhasil diupload",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                location.reload();
              }
            });
          }
        })
        .catch((error) => {
          var error_res = error.response.data.meta;
          // console.log(error_res);
          if (error_res.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error_res.message,
            });
          }
        });
    },

    getExcelBenih() {
      //   var win = window.open(
      // 	  "https://simpel2.pertanian.go.id/endpoint/public/api/excel-list-benih?permohonan_id=" +
      var win = window.open(
        this.apiLink +
          "api/excel-list-benih?permohonan_id=" +
          this.detail_data.permohonan.id +
          "&nib=" +
          this.detail_data.permohonan.nib,
        "_blank"
      );
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getFileWithLink(files) {
      var win = window.open(files, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    getFile(files) {
      let modifiedUrl = files.replace(/^\//, "");
      var win = window.open(this.apiLink + modifiedUrl, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
  },
  computed: {
    badgeClass() {
      const status =
        this.permohonan_status &&
        this.permohonan_status.status_permohonan_terbaru?.status_permohonan
          ?.st_nama;
      switch (status) {
        case "Selesai":
          return "badge badge-success";
        case "Dibatalkan":
          return "badge badge-danger";
        case "Menunggu Perbaikan":
          return "badge badge-warning";
        case "Evaluasi Dokumen Teknis":
          return "badge badge-primary";
        default:
          return "badge badge-info";
      }
    },
    badgeText() {
      const status =
        this.permohonan_status &&
        this.permohonan_status.status_permohonan_terbaru?.status_permohonan
          ?.st_nama;
      const ditjen =
        this.permohonan_status.status_permohonan_terbaru?.status_permohonan
          ?.roles?.ditjen;

      return `Menunggu ${status} oleh ${ditjen}`;
    },
    totalPbJumlah() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah),
        0
      );
      return parseFloat(total.toFixed(2));
    },
    totalPbJumlahDisetujui() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_jumlah_disetujui),
        0
      );
      return parseFloat(total.toFixed(2));
    },
    totalPbNilaiTotal() {
      if (
        !this.detail_data ||
        !this.detail_data.permohonan ||
        !this.detail_data.permohonan.permohonan_benih
      )
        return 0;
      const total = this.detail_data.permohonan.permohonan_benih.reduce(
        (total, item) => total + Number(item.pb_nilai_total),
        0
      );
      return parseFloat(total.toFixed(2));
    },
  },
  mounted() {
    $(".select2").select2({
      placeholder: "-- Pilih --",
    });
  },

  created() {
    axios
      .get(this.apiLink + "api/permohonan/detail", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan = response.data;
        var res_permohonan_data = res_permohonan.data;
        if (res_permohonan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan.data.message,
          });
        } else {
          this.detail_data = res_permohonan_data;
          console.log("data detail : ", res_permohonan_data);
          this.data_benih = res_permohonan_data.permohonan_benih;
          var is_benih_aropt = false;
          $.each(
            res_permohonan_data.permohonan.permohonan_benih,
            function (index, val) {
              // console.log(val.is_aropt);
              if (!val.is_aropt) {
                is_benih_aropt = true;
              }
            }
          );
          this.benih_aropt = is_benih_aropt;
          if (res_permohonan_data.doc_realisasi_ref) {
            this.file_dokumen_realisasi.dokumen_permohonan_id =
              res_permohonan_data.doc_realisasi_ref.dokumen_permohonan_id;
          } else {
            let permohonanDokumen =
              res_permohonan_data.permohonan.permohonan_dokumen;
            let dokumenDitemukan = permohonanDokumen.find(
              (dokumen) =>
                dokumen.dokumen_permohonan.dokumen_persyaratan_permohonan_id ===
                30
            );
            this.file_dokumen_realisasi.dokumen_permohonan_id =
              dokumenDitemukan.dokumen_permohonan_id;
          }
        }
      });
    // Get Process Status
    axios
      .get(this.apiLink + "api/permohonan/status", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan_status = response.data;
        var res_permohonan_status_data = res_permohonan_status.data;
        if (res_permohonan_status.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan_status.data.message,
          });
        } else {
          console.log("status : ", res_permohonan_status_data);
          this.permohonan_status = res_permohonan_status_data;
        }
      });
    // console.log(this.session_data);
  },
};
</script>
